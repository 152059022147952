import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PageTemplateDetails from "../components/PageTemplateDetails";

class PageTemplate extends React.Component {
  render() {
    const { title, subtitle, url, author } = this.props.data.site.siteMetadata;
    const origin = typeof window !== "undefined" ? window.location.origin : "";
    const description = subtitle;
    const profilePic = require("../pages/logo.png");
    const metaTitle = `${title} - ${subtitle}`;
    return (
      <Layout>
        <div>
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={url} />
            <meta property="og:url" content={`${url ? url : origin}`} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={`${origin}${profilePic}`} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={author.twitter} />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={description} />
            {/* <meta
              name="twitter:image"
              content={`${origin}${encodeURI(
                profilePic
              )}?${new Date().getTime()}`}
            /> */}
            <meta name="monetization" content="$ilp.uphold.com/QzG6Ww2rKUzq" />
            {
              <script type="application/ld+json">
                {JSON.stringify({
                  "@context": "http://schema.org",
                  "@type": "Article",
                  name: "Box Piper",
                  author: {
                    "@type": "Person",
                    name: "Box Piper",
                  },
                  image:
                    "https://www.boxpiper.com/static/logo-93dadb972507385b2e0a34bc919fb891.gif",
                  articleSection:
                    "What is Open AI OpenAI’s mission is to ensure that artificial general intelligence (AGI)— highly autonomous systems that outperform humans at most Read September 2020Psychological The Pratfall Effect How making mistakes make you more likeable. When a person makes a mistake or acts in a clumsy way that might even make people laugh, they are found to be more likeable. Read September 2020Scrapping Scraper API - Proxy API for Web Scraping Scraper API is a proxy API for web scraping. They offer an API that handles proxies, browsers, and CAPTCHAs, so you can get the HTML from any web page with a simple API call. Read September 2020Application Security What is RASP With so many vulnerable apps running in the enterprise, the challenge for network defenders is how to protect those apps from attack. One way is to have the applications protect themselves by identifying and blocking attacks in real time. That's what technology called Runtime Application Self-Protection (RASP) does.",
                  url: "https://www.boxpiper.com/",
                })}
              </script>
            }
          </Helmet>
          <PageTemplateDetails {...this.props} />
        </div>
      </Layout>
    );
  }
}

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        feature {
          label
          path
        }
        deployed {
          label
          path
        }
        author {
          name
          email
          telegram
          twitter
          github
          rss
          vk
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
        showYear
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`;
